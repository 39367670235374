import * as React from "react";
import "./Banner.css";

/**
 * This is the banner from Roach Rad.io.
 * The more messages inside of marquee__content the less spaced out they get from eachother in large windows.
 */
// eslint-disable-next-line react/prop-types
const Banner = ({ bannerMessage, reverse = false, white = false }) => {
  // eslint-disable-next-line no-unused-vars
  return (
    <div className={`Banner ${white ? "white" : "black"}`}>
      <div className="marquee">
        <div
          className={reverse ? "marquee__content reverse" : "marquee__content"}
        >
          <div>{bannerMessage}</div>
          <div>{bannerMessage}</div>
          <div>{bannerMessage}</div>
        </div>
        <div
          className={reverse ? "marquee__content reverse" : "marquee__content"}
          aria-hidden="true"
        >
          <div>{bannerMessage}</div>
          <div>{bannerMessage}</div>
          <div>{bannerMessage}</div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
