import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Navbar from "../components/navbar";
import * as style from "./index.module.css";
import * as aboutstyle from "./about.module.css";
import "remixicon/fonts/remixicon.css";
import Banner from "../components/Banner";
import "animate.css";
import { Link } from "gatsby";
import { MdArrowBack } from "@react-icons/all-files/md/MdArrowBack";

// define constants
const NAME = "leo martinez";
const EMAIL = "bleo@mit.edu";

const AboutPage = () => {
  React.useEffect(() => {
    // Add the white colors to the body element
    document.body.classList.add(aboutstyle.about_page_colors);
    return () => {
      // Remove the class from the body element when the user leaves the about page
      document.body.classList.remove(aboutstyle.about_page_colors);
    };
  }, []);

  return (
    <div className={`${style.viewport} ${style.gallery_scroll}  `}>
      <Navbar name={NAME} email={EMAIL} color="black"></Navbar>

      <Link className={aboutstyle.back_button} to="/">
        <MdArrowBack
          size="2em"
          className="animate__animated animate__fadeIn animate__faster animate__delay-1s"
        />
      </Link>

      <div className={aboutstyle.page_flex_container}>
        <div className={aboutstyle.left_container}>
          <div className={aboutstyle.adjectives}>
            <div className={`${aboutstyle.adjectives_text}`}>
              <div className=" animate__animated animate__fadeInLeft animate__faster animate__delay-1s">
                DEVELOPER.
              </div>
              <div className=" animate__animated animate__fadeInLeft animate__fast animate__delay-1s">
                DESIGNER.
              </div>
              <div className=" animate__animated animate__fadeInLeft animate__delay-1s">
                CREATOR.
              </div>
            </div>
          </div>
          <div className={aboutstyle.description_text}>
            <p className="animate__animated animate__fadeInUp animate__delay-2s">
              Hello, my name is Leo - I am a web developer and designer!
            </p>
            <p className="animate__animated animate__fadeInUp animate__delay-2s">
              I hold a Bachelor's degree in Electrical Engineering and Computer
              Science from the Massachusetts Institute of Technology.
            </p>
            <p className="animate__animated animate__fadeInUp animate__delay-2s">
              Currently, I am dedicated to honing my expertise in creating
              exceptional front-end applications, with a particular focus on
              React. My passion also extends to the realm of graphic design and
              UI/UX design. Through the fusion of my technical and design
              skills, I strive to deliver memorable user experiences that
              redefine industry standards.
            </p>
          </div>
        </div>
        <div
          className={`${aboutstyle.figma_container} animate__animated animate__fadeInRight animate__delay-2s`}
        >
          <iframe
            style={{
              border: "1px solid rgba(0, 0, 0, 0.1)",
              width: "100%",
              height: "100%",
            }}
            src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2FwgdCWCeqAxqMrUTm6VnYkG%2FPortfolio%3Ftype%3Dwhiteboard%26node-id%3D0%253A1%26t%3D90hnODp46if5MjrQ-1&theme=dark"
          ></iframe>
        </div>
      </div>

      <div className={aboutstyle.dj_section}>
        <div className={aboutstyle.relative_container_dj}>
          <div className={aboutstyle.absolute_container}>
            <div className={aboutstyle.dj_left_side}>
              <section>
                <Banner bannerMessage="DEEJAY" reverse />
                <Banner bannerMessage="ORGANIZER" white={true} />
                <Banner bannerMessage="FREETEKNO ENTHUSIAST" reverse />
              </section>
              <section className={aboutstyle.dj_description}>
                <h2 className={aboutstyle.dj_mini_header}>
                  In the fight for a better future, silence will not protect us.
                </h2>
                <p className={aboutstyle.dj_bio_text}>
                  I am a believer in the ideas brought forth during the
                  free-party, teknival culture of the 90s. I am hoping to keep
                  them alive and pumping in the present and the future. Reach
                  out if you want to “make some noize”!
                </p>
              </section>
            </div>
            <div className={aboutstyle.dj_middle}>
              <i
                className={`ri-soundcloud-fill ${aboutstyle.social_icons}`}
              ></i>
              <i
                className={`ri-instagram-fill ${aboutstyle.social_icons}`}
                onClick={() => {
                  window.open(
                    "https://www.instagram.com/blaoh_m/?utm_source=ig_web_button_share_sheet&igshid=OGQ5ZDc2ODk2ZA==",
                    "_blank"
                  );
                }}
              ></i>
              <h3 className={aboutstyle.dj_contact_text}>CONTACT</h3>
            </div>
            <div className={aboutstyle.dj_right_side}>
              <StaticImage src="../images/Blomie3_sprial.png" />
              <div className={aboutstyle.smileys}>
                {
                  /** Creates 3 Static image objects programatically */
                  Array(3).fill(
                    <StaticImage
                      width={100}
                      aspectRatio={1}
                      src="../images/spiral_vector.png"
                    />
                  )
                }
              </div>
            </div>
          </div>

          <div className={aboutstyle.but_wait_text}>
            <div
              className=" animate__animated animate__fadeIn"
              style={{ color: "black" }}
            >
              BUT WAIT, THERE'S MORE!
            </div>
            <div
              className={`animate__animated animate__fadeIn ${aboutstyle.stroke_1} ${aboutstyle.stroke}`}
              style={{ color: "whitesmoke" }}
            >
              BUT WAIT, THERE'S MORE!
            </div>
            <div
              className={`animate__animated animate__fadeIn ${aboutstyle.stroke_2} ${aboutstyle.stroke}`}
              style={{ color: "whitesmoke" }}
            >
              BUT WAIT, THERE'S MORE!
            </div>
            <div
              className={`animate__animated animate__fadeIn ${aboutstyle.stroke_3} ${aboutstyle.stroke}`}
              style={{ color: "whitesmoke" }}
            >
              BUT WAIT, THERE'S MORE!
            </div>
            <div
              className={`animate__animated animate__fadeIn ${aboutstyle.stroke_4} ${aboutstyle.stroke}`}
              style={{ color: "whitesmoke" }}
            >
              BUT WAIT, THERE'S MORE!
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutPage;
