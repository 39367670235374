// extracted by mini-css-extract-plugin
export var about_page_colors = "about-module--about_page_colors--eb46e";
export var absolute_container = "about-module--absolute_container--c3e9b";
export var adjectives = "about-module--adjectives--bd0e4";
export var adjectives_text = "about-module--adjectives_text--21832";
export var back_button = "about-module--back_button--cde95";
export var big_bold_text = "about-module--big_bold_text--9ba6b";
export var bio = "about-module--bio--885ad";
export var but_wait_text = "about-module--but_wait_text--cc745";
export var colorTransition = "about-module--color-transition--93337";
export var description_text = "about-module--description_text--86222";
export var dj_bio_text = "about-module--dj_bio_text--c0e95";
export var dj_contact_text = "about-module--dj_contact_text--4e1aa";
export var dj_description = "about-module--dj_description--0f85d";
export var dj_left_side = "about-module--dj_left_side--5ee81";
export var dj_middle = "about-module--dj_middle--855e3";
export var dj_mini_header = "about-module--dj_mini_header--a4f76";
export var dj_right_side = "about-module--dj_right_side--90834";
export var dj_section = "about-module--dj_section--f15b9";
export var figma_container = "about-module--figma_container--e0bed";
export var info = "about-module--info--ad735";
export var left_container = "about-module--left_container--392fb";
export var location_text = "about-module--location_text--21daa";
export var medium_bold_text = "about-module--medium_bold_text--75bb4";
export var page_flex_container = "about-module--page_flex_container--e87e1";
export var picture = "about-module--picture--626eb";
export var relative_container_dj = "about-module--relative_container_dj--64fe4";
export var small_bold_text = "about-module--small_bold_text--73d9b";
export var smileys = "about-module--smileys--83c20";
export var social_icons = "about-module--social_icons--20c18";
export var stroke = "about-module--stroke--efccf";
export var stroke_1 = "about-module--stroke_1--887f4";
export var stroke_2 = "about-module--stroke_2--86049";
export var stroke_3 = "about-module--stroke_3--4d604";
export var stroke_4 = "about-module--stroke_4--05b5f";